class CountDown {
    constructor(counter) {
        this.counter = counter
        this.finishDate = counter.dataset.finishDate
        // Set the date we're counting to down
        this.countDownDate = new Date(this.finishDate).getTime()

        this.control()
    }

    control() {
        // Update the count down every 1 second
        const updateCount = setInterval(() => {

            // Get today's date and time
            const now = new Date().getTime()

            // Find the distance between now and the count down date
            const distance = this.countDownDate - now

            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(distance / (1000 * 60 * 60 * 24))
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            const seconds = Math.floor((distance % (1000 * 60)) / 1000)

            // Output the result in an element with counter
            this.counter.innerHTML = `<p>
                ${days}:
                ${hours < '10' ? '0' + hours : hours}:
                ${minutes < '10' ? '0' + minutes : minutes}
                ${seconds < '10' ? '0' + seconds : seconds}
            </p>`

            // If the count down is over, write expired text
            if (distance < 0) {
                clearInterval(updateCount)
                this.counter.innerHTML = `<p>Date is expired</p>`
            }
        }, 1000)
    }
}

document.addEventListener('DOMContentLoaded', () => {
    for (const counter of document.querySelectorAll('.data-count-down')) {
        new CountDown(counter)
    }
})
