addEventListener('DOMContentLoaded', () => {
    for (const video of document.querySelectorAll('.video-box')) {

        video.querySelector('.play-video').addEventListener('click', () => {
            video.querySelector('.video-open').classList.add('video-box-show')
        })

        video.querySelector('.video-close').addEventListener('click', () => {
            video.querySelector('.video-open').classList.remove('video-box-show')
        })
    }
})
