/* global CMS */

/**
 * Submenu
 */

function changeBtn(submenu) {
    if (submenu.classList.contains('collapsed')) {
        submenu.querySelector('.submenu-hide').classList.remove('show')
        submenu.querySelector('.submenu-show').classList.add('show')
    } else {
        submenu.querySelector('.submenu-hide').classList.add('show')
        submenu.querySelector('.submenu-show').classList.remove('show')
    }
}

/**
 * Attach event listeners to submenu elements.
 */
function attach() {
    const bannerMenu = document.querySelectorAll('.banner-menu')
    if (bannerMenu) {
        bannerMenu.forEach(submenu => {
            if (submenu.dataset.listenersAttached === 'true') {
                return // Listeners were attached when the page was loaded.
            }

            changeBtn(submenu)
            submenu.querySelector('.txt-primary').addEventListener('click', () => {
                submenu.classList.toggle('collapsed')
                changeBtn(submenu)
            })

            submenu.dataset.listenersAttached = 'true'
        })
    }
}

// Attach event listeners when page is loaded.
addEventListener('DOMContentLoaded', () => attach())

// Attach event listeners when the plugin is inserted.
if (typeof CMS !== 'undefined') {
    CMS.$(window).on('cms-content-refresh', () => {
        attach()
    })
}
