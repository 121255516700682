/**
 *  Scroll Animation
 *  Detect request animation frame
 *
 */
const IeFallback = callback => window.setTimeout(callback, 1000 / 60),
    scroll = window.requestAnimationFrame || IeFallback,
    elementsToShow = document.querySelectorAll('.show-on-scroll')

// Helper function from: http://stackoverflow.com/a/7557433/274826
function isElementInViewport(el) {
    const rect = el.getBoundingClientRect()
    return (
        (rect.top <= 0 && rect.bottom >= 0)
            ||
        (rect.bottom >= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.top <= (window.innerHeight || document.documentElement.clientHeight))
            ||
        (rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
    )
}

function loop() {
    Array.prototype.forEach.call(elementsToShow, (element) => {
        if (isElementInViewport(element)) {
            element.classList.add('is-visible')
        }
    })
    scroll(loop)
}

// Call the loop for the first time
loop()
