/**
 * Hamburger menu
 *
 */
const hamburger = document.querySelector('.hamburger-menu')
if (hamburger) {
    hamburger.addEventListener('click', () => {
        hamburger.classList.toggle('change')
        document.querySelector('body').classList.toggle('navbar-dropdown-open')
    })
}
