/* global $ */

/**
 * Open first collapse.
 */
for (const node of document.querySelectorAll('.collapse-open div[id^=trigger]')) {
    node.click()
}

/**
 * Don't close all collapse.
 */
for (const node of document.querySelectorAll('.collapse-image')) {
    node.addEventListener('click', function(e) {
        if (e.target.parentNode.getAttribute('aria-expanded') === 'true') {
            e.stopPropagation()
        }
    })
}

/**
 * Open first tab.
 */
for (const item of document.querySelectorAll('.nav-tabs li:first-of-type')) {
    item.querySelector('.nav-link').classList.add('active')
}

for (const item of document.querySelectorAll('.tab-content .tab-pane:first-of-type')) {
    item.classList.add('active')
}

/**
 * Close first tab.
 */
for (const tab of document.querySelectorAll('.tabs-close')) {
    tab.querySelector('.nav-tabs li:first-of-type .nav-link').classList.remove('active')
    tab.querySelector('.tab-content .tab-pane:first-of-type').classList.remove('active')
}

/* ***************************************** */
/* GLOSSARY and other tabs with anchor links */
/* ***************************************** */
/*
 * The mechanism expects standard Bootstrap DOM, it means something as follows:
 * <div class="tabs">
 *     <ul class="nav">
 *         <li><a href="#tab-xyz" data-toggle="tab">Link</a></li>
 *     </ul>
 *     <div class="tab-content">
 *         <div class="tab-pane" id="tab-xyz">
 *             <a name="example">Example</a>
 *             <a href="#example">Link to example</a>
 *         </div>
 *     </div>
 * </div>
 */

/**
 * Display the tab, which contains an anchor and scroll to the element after a while.
 *
 * @param {string} destName - The destination anchor we want to show.
 */
function displayAnchor(destName) {
    for (const tab of document.querySelectorAll('.tabs')) {
        const tabContent = tab.querySelector('.tab-content')
        const destination = tabContent.querySelector(`a[name="${destName}"]`)

        if (destination) {
            const tabId = destination.closest('.tab-pane').getAttribute('id')
            const nav = tab.querySelector('.nav')
            const link = nav.querySelector(`a[href="#${tabId}"]`)

            // The animation will be skipped in case the pane is already active
            $(link).tab('show')

            // Set timeout to scroll on the element
            setTimeout(() => {
                destination.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                })
            }, 500)

            // Break the for cycle
            return
        }
    }
}

window.addEventListener('DOMContentLoaded', () => {

    // Set event listeners for all the anchors inside the glossary (or tabs in general)
    for (const link of document.querySelectorAll('.tabs .tab-content a[href^="#"]')) {
        link.addEventListener('click', () => {
            displayAnchor(link.getAttribute('href').substr(1))
        })
    }

    // If there is already a hash in the URL, try to find and display the anchor
    if (window.location.hash) {
        displayAnchor(window.location.hash.substr(1))
    }

    // https://getbootstrap.com/docs/3.3/javascript/#tabs-usage
    $('#list-tab a').on('click', e => {
        e.preventDefault()
        $('#list-tab .active').removeClass('active')
        $(this).tab('show')
        $(e.target.parentNode).addClass('active')
    })

})
